import React from 'react'
import styled from 'styled-components'

import {Container, Row, Col} from "reactstrap"

import BackgroundImage from 'gatsby-background-image'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.gray900};
  opacity: 0.5;
`

const BackgroundSection = ({ className, imageData, title, subTitle, children }) => {
  return (        
    <BackgroundImage
        Tag="header"
        fluid={imageData}
        backgroundColor={`#040e18`}
        >
        <Overlay/>
        <Container>
          <Row>
              <Col className="col-12">
                  {children}
              </Col>    
          </Row>
        </Container>    
    </BackgroundImage>    
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
    margin-bottom: 50px;
    background: no-repeat center center;
    background-color: $gray-600;
    background-attachment: scroll;
    position: relative;
    @include background-cover;
`

export default StyledBackgroundSection