import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Masonry from "react-masonry-component"

import { Container, Row, Col} from 'reactstrap'
// import { HelmetDatoCms } from "gatsby-source-datocms";

import Layout from "../components/layout"
import MastHead from "../components/masthead"

import styled from 'styled-components'

let SiteHeading = styled.div`
  padding: 200px 0 150px;
  color: white;
  @media only screen and (min-width: 768px) {
    padding: 200px 0;
  }
  text-align: center;
`

let H1 = styled.h1`
  font-size: 50px;
  margin-top: 0;
  @media only screen and (min-width: 768px) {
    font-size: 80px;
  }
`

let SubHeading = styled.span`
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
  display: block;
  margin: 10px 0 0;
  @include sans-serif-font;
  @media only screen and (max-width: 400px) {
    font-size: 30px;
  }
`

let PostLink = styled(Link)`
  color: ${props => props.theme.gray900};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.primary};
  }
`

let PostTitle = styled.h2`
  font-size: 30px;
  // margin-top: 30px;
  // margin-bottom: 10px;
`

let PostSubTitle = styled.h3`
  font-weight: 300;
  margin: 0 0 10px;
  @include serif-font;
  font-family: 'Cormorant', 'Times New Roman', serif;
`

let PostMeta = styled.p`
  font-size: 18px;
  font-style: italic;
  margin-top: 0;
  margin: 0px 0px;
  color: ${props => props.theme.gray600};
`

let PostCard = styled.div`
  // margin-bottom: 30px;

  @media screen and (min-width: 0px) {
    box-sizing: border-box;
    width: 100%;
    float: left;
    padding-left: 15px;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }

  @media screen and (min-width: 900px) {
    width: 50%;
  }

  @media screen and (min-width: 1100px) {
    width: 33%;
    padding-left: 40px;
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.datoCmsHomePage?.title || `Title`
  const home = data.datoCmsHomePage
  const posts = data.allDatoCmsBlog.nodes

  home.seoMetaTags.tags[0].content = home.title + " | Indus Chadha"

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>      
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} seo={home.seoMetaTags}>
      <MastHead imageData={home.banner.fluid}>
          <SiteHeading>
            <H1><i>Ina</i>rticulately</H1>
            <SubHeading>{home.subTitle}</SubHeading>
          </SiteHeading>
      </MastHead>
      <Container className="pt-5">            
        <Row>   
          <Col className="mx-auto">                               
            <Masonry>
              {posts.map(post => {
                  return (
                    <PostCard key={post.slug}>
                      <figure className="rounded p-3 bg-white shadow-sm position-relative">
                        {post.hero && 
                          <Img fluid={post.hero.fluid} />
                        }   
                        <figcaption className="p-4 card-img-bottom">                                              
                          <PostLink to={post.slug} className="stretched-link">
                            <PostTitle>
                                {post.title}
                            </PostTitle>
                            <PostSubTitle>
                              {post.subTitle}
                            </PostSubTitle>
                          </PostLink>                        
                          <PostMeta>{post.publishDate}</PostMeta>
                        </figcaption>                 
                      </figure>
                    </PostCard>
                  )
                })}
            </Masonry>
          </Col>
        </Row>
      </Container>                  
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
{
  datoCmsHomePage  {
    banner {
      fluid (maxWidth:1440, imgixParams: {w: "1440"}){
        ...GatsbyDatoCmsSizes
      }
    }
    title
    subTitle  
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
  }
  allDatoCmsBlog(sort: {fields: [publishDate], order: DESC}) {
    nodes {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY")
      subTitle
      hero {
        fluid(maxWidth:500, imgixParams: {w: "500"}){
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
}

`
